import "~/node_modules/slick-carousel/slick/slick.css";
import "~/node_modules/slick-carousel/slick/slick-theme.css";
import "./styles/slider.scss";

(function($) {
	var slides = $(".slides");
	if (!slides) {
		return;
	}

	var defOptions = {
		slidesPerRow: 1,
		rows: false
	};

	Array.from(slides).forEach(slide => {
		var options = JSON.parse(slide.getAttribute("data-slick"));
		options = { ...defOptions, ...options };
		console.log(options);
		$(slide).slick(options);
	});
})(jQuery);
