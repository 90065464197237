(function () {
	var map = document.querySelectorAll("div.map");
	if (!map) {
		return;
	}

	var mapObserver = new IntersectionObserver((entries) => {
		// console.log(entries);
		// var map = new GgMap(entries[0].target)
		if (entries[0].isIntersecting) {
			initMap();
			mapObserver.unobserve(entries[0].target);
		}
	});
	mapObserver.observe(map[0]);

	function initMap() {
		for (const m of map) {
			var markerEls = m.querySelectorAll(".marker");

			var gmap = new google.maps.Map(m, {
				zoom: 10,
				center: { lat: -36.4536602, lng: 143.90711 },
				styles: JSON.parse(mapData.mapStyles),
			});

			// Setup Spider map
			var oms = new OverlappingMarkerSpiderfier(gmap, {
				markersWontMove: true,
				markersWontHide: true,
			});

			var bounds = new google.maps.LatLngBounds();

			var infowindow = new google.maps.InfoWindow({ content: "" });

			var markers = [];
			for (const mark of markerEls) {
				var marker = new google.maps.Marker({
					position: JSON.parse(mark.getAttribute("data-latlng")),
					title: mark.children.length
						? mark.children[0].innerText
						: null,
					// icon: mapData.mapIcon,
				});
				marker.el = mark;

				var loc = new google.maps.LatLng(
					marker.position.lat(),
					marker.position.lng()
				);
				bounds.extend(loc);

				marker.addListener("spider_click", function () {
					infowindow.close();
					infowindow.setContent(this.el.innerHTML);
					infowindow.open(gmap, this);
				});

				// Add marker to spider map
				oms.addMarker(marker);

				markers.push(marker);
			}

			// Center Map
			gmap.fitBounds(bounds);
			gmap.panToBounds(bounds);

			// Setup marker clusters
			var markerCluster = new MarkerClusterer(gmap, markers, {
				maxZoom: 13,
				imagePath:
					"https://developers.google.com/maps/documentation/javascript/examples/markerclusterer/m",
			});

			gmap.addListener("center_changed", function () {});

			gmap.addListener("click", function (MouseEvent) {
				infowindow.close();
			});

			m.style.display = "block";
		}
	}
})();
